
import Vue from 'vue'
export default Vue.extend({
  props: {
    padding: {
      type: String,
      default: '20px',
      required: false,
    },
  },
})
