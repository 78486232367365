import { MutationTree, ActionTree } from 'vuex'

export const state = () => ({
  contactFormType: '' as string,
  activeTab: 'news',
})

export type RootState = ReturnType<typeof state>

export const mutations: MutationTree<RootState> = {
  setFormType(state, value: string) {
    state.contactFormType = value
  },
  setActiveTab(state, value: string) {
    state.activeTab = value
  },
}

export const actions: ActionTree<RootState, RootState> = {}
