
import Vue from 'vue'
export default Vue.extend({
  props: {
    color: {
      type: String,
      required: false,
      default: '#004adb',
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    background: {
      type: String,
      required: false,
      default: '#fff',
    },
    link: {
      type: String,
      required: false,
      default: '',
    },
    dark: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    clickEvent() {
      this.$emit('click')
      if (this.link) {
        this.$router.push(this.link)
      }
    },
  },
})
