
import Vue from 'vue'
export default Vue.extend({
  data() {
    return {
      isOpen: false,
      navList: [
        {
          text: 'スリーズプロとは',
          to: '/infoThreeasePro',
          subItems: [
            { text: 'トップページ', to: '/' },
            { text: 'スリーズプロの特徴', to: '/featureOverView' },
            { text: '導入効果', to: '/benefits' },
            { text: 'スリーズプロの想い', to: '/ourVision' },
          ],
        },
        {
          text: '機能一覧',
          to: '/functions',
          subItems: [
            { text: '予約', to: '/functions/reservations' },
            { text: '電子カルテ', to: '/functions/karte' },
            { text: 'CRM', to: '/functions/crm' },
            { text: 'POS', to: '/functions/pos' },
          ],
        },
        {
          text: 'プラン',
          to: '/plan',
        },
        {
          text: '導入企業・店舗様の声',
          to: '/interviews',
        },
        {
          text: 'お知らせ',
          to: '/news',
        },
        {
          text: 'よくある質問',
          to: '/faq',
        },
        {
          text: 'セミナー情報',
          to: '/seminar',
        },
        {
          text: 'WEB集客',
          to: '/web-marketing',
        },
      ],
    }
  },
})
