import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email, integer } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: '必須入力です',
})

extend('email', {
  ...email,
  message: '適切なメールアドレスを入力してください',
})

extend('agree', {
  validate: (value) => value === true,
  message: '同意してください',
})

extend('integer', {
  ...integer,
  message: '整数を入力してください',
})
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
