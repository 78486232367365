
import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      smart治療院とは: [
        { text: 'スリーズプロの特徴', to: '/featureOverView' },
        { text: '導入効果', to: '/benefits' },
        { text: 'スリーズプロの想い', to: '/ourVision' },
        {
          text: 'よくある質問',
          to: '/faq',
        },
      ],
      featureList: [
        { text: '予約', to: '/functions/reservations' },
        { text: 'CRM', to: '/functions/crm' },
        { text: 'POS', to: '/functions/pos' },
        { text: '電子カルテ', to: '/functions/karte' },
      ],
      planList: [{ text: 'プラン', to: '/plan' }],
      newsList: [{ text: 'お知らせ', to: '/news' }],
      operationsList: [
        { text: 'お問い合わせ', to: '/contact' },
        { text: 'プライバシーポリシー', to: '/privacypolicy' },
        { text: '運営会社', href: 'https://www.three-sides.co.jp/' },
      ],
    }
  },
})
