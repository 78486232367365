
import Vue from 'vue'
export default Vue.extend({
  props: {
    spPadding: {
      type: String,
      required: false,
      default: '0 25px',
    },
    padding: {
      type: String,
      required: false,
      default: '0 50px',
    },
    align: {
      type: String,
      required: false,
      default: 'items-center',
    },
  },
})
