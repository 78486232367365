
import Vue from 'vue'
export default Vue.extend({
  props: {
    isWhite: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
})
