
import Vue, { PropOptions } from 'vue'
import { mdiChevronDown } from '@mdi/js'
import { NavList } from '~/types'

export default Vue.extend({
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    } as PropOptions<NavList[]>,
  },
  data() {
    return {
      mdiChevronDown,
      isOpen: false,
      openListIndex: [] as number[],
    }
  },
  methods: {
    setOpenListIndex(index: number) {
      if (this.isOpenList(index)) {
        this.openListIndex = this.openListIndex.filter((item) => item !== index)
      } else {
        this.openListIndex.push(index)
      }
    },
    isOpenList(index: number) {
      return this.openListIndex.some((item) => item === index)
    },
    toLink(link: string) {
      this.isOpen = false
      this.$router.push(link)
    },
  },
})
