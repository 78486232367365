
import Vue from 'vue'
export default Vue.extend({
  props: {
    title: {
      type: String,
      required: false,
      default: ' ',
    },
    list: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
  },
})
