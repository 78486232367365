
import Vue from 'vue'
import { mdiChevronDown } from '@mdi/js'

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    dropDownList: {
      type: Array,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'white',
    },
  },
  data() {
    return {
      mdiChevronDown,
      showDropDown: false,
      isHoveringList: false,
      debounce: null as NodeJS.Timeout | null,
    }
  },
  methods: {
    show() {
      this.showDropDown = !this.showDropDown
    },
    mouseleaveItem() {
      if (this.debounce) clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        if (this.isHoveringList) return
        this.showDropDown = false
      }, 10)
    },
    hoverList() {
      this.showDropDown = true
      this.isHoveringList = true
    },
    hoverLeaveList() {
      this.showDropDown = false
      this.isHoveringList = false
    },
  },
})
